/* Styling the button component */
.btn {
  padding: 1em 1.8em;
  position: relative;
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  border: none;
  background-color: var(--btn-color);
  color: var(--btn-txt-color);
  transition: 0.3s ease-in-out;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  z-index: 1;
  overflow: hidden;
  clip-path: polygon(
    0 0,
    calc(100% - var(--edge-size)) 0,
    100% var(--edge-size),
    100% 100%,
    var(--edge-size) 100%,
    0 calc(100% - var(--edge-size))
  );
}

.btn.container {
  color: var(--container-btn-txt-color);
  background-color: var(--container-btn-color);
}

/* Styling the hover effect */
.btn::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  z-index: -1;
  background-color: var(--btn-txt-color);
  transition: transform 0.3s ease-in-out;
}

.btn.container::after {
  background-color: var(--container-btn-txt-color);
}

.btn.top::after {
  transform-origin: bottom;
  transform: scaleY(0);
}

.btn.bottom::after {
  transform-origin: top;
  transform: scaleY(0);
}

.btn.right::after {
  transform-origin: left;
  transform: scaleX(0);
}

.btn.left::after {
  transform-origin: right;
  transform: scaleX(0);
}

.btn.top-left::after {
  transform-origin: bottom right;
  transform: scale(0);
}

.btn.top-right::after {
  transform-origin: bottom left;
  transform: scale(0);
}

.btn.bottom-left::after {
  transform-origin: top right;
  transform: scale(0);
}

.btn.bottom-right::after {
  transform-origin: top left;
  transform: scale(0);
}

.btn:hover {
  color: var(--btn-color);
}

.btn.container:hover {
  color: var(--container-btn-color);
}

.btn.top:hover::after {
  transform-origin: top;
  transform: scaleY(1);
}

.btn.bottom:hover::after {
  transform-origin: bottom;
  transform: scaleY(1);
}

.btn.right:hover::after {
  transform-origin: right;
  transform: scaleX(1);
}

.btn.left:hover::after {
  transform-origin: left;
  transform: scaleX(1);
}

.btn.top-left:hover::after {
  transform-origin: top left;
  transform: scale(1);
}

.btn.top-right:hover::after {
  transform-origin: top right;
  transform: scale(1);
}

.btn.bottom-left:hover::after {
  transform-origin: bottom left;
  transform: scale(1);
}

.btn.bottom-left:hover::after {
  transform-origin: bottom left;
  transform: scale(1);
}
