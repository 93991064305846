/* Styling the Projects component */
.projects-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 1em 5em;
  scroll-margin-top: 5em;
}

.projects-limiter {
  width: 100%;
  max-width: 1500px;
  display: flex;
  flex-direction: column;
}

.projects-container h2 {
  color: var(--txt-color);
  transition: 0.3s ease-in-out;
}

.projects-container p {
  font-size: clamp(1rem, 3vw, 1.2rem);
  color: var(--txt-color);
  line-height: 30px;
  margin-block: 1.5em;
  transition: 0.3s ease-in-out;
}

.projects-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 0.2em;
}

@media (width < 1000px) {
  .projects-list {
    grid-template-columns: 1fr;
  }
}
