/* Styling the Navbar component */
.navbar-container {
  background-color: var(--navbar-color);
  position: fixed;
  inset: 0 0 auto 0;
  padding: 1em;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 2;
}

.nav-limiter {
  width: 100%;
  max-width: 1500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navbar-buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.navbar-buttons button,
.navbar-buttons a {
  background-color: transparent;
  position: relative;
  border: none;
  cursor: pointer;
  color: var(--navbar-txt-color);
  font-weight: 700;
  font-size: 1rem;
  padding: 0.5em 1em;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  text-decoration: none;
}

.navbar-buttons button:focus .navbar-buttons a:focus {
  outline: none;
}

.navbar-buttons button::before,
.navbar-buttons a::before {
  content: "";
  width: 100%;
  position: absolute;
  background-color: var(--navbar-txt-color);
  height: 2px;
  inset: auto 0 0 0;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
  transform-origin: right;
}

.navbar-buttons button:hover::before,
.navbar-buttons a:hover::before,
.navbar-buttons button:focus::before,
.navbar-buttons a:focus::before,
.navbar-buttons button.visible::before {
  transform: scaleX(1);
  transform-origin: left;
}

.navbar-links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-links button {
  padding-block: 0.7em;
}

.navbar-links button span {
  display: none;
}

/* Styling mobile mode */
@media (width < 1000px) {
  .navbar-links {
    position: fixed;
    inset: auto 0 0 0;
    background-color: var(--navbar-color);
    padding: 0.7em;
    justify-content: space-between;
    z-index: 3;
  }

  .navbar-links p {
    display: none;
  }

  .navbar-links button span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    scale: 1.2;
  }

  .navbar-links button span small {
    font-size: 0.4rem;
  }
}
