/* Styling the Footer component */
.footer-container {
  padding: 5em 1em;
  background-color: var(--navbar-color);
  width: 100%;
  position: relative;
  z-index: 1;
  margin-top: -4em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-limiter {
  width: 100%;
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.footer-page-links {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-page-links button {
  background-color: transparent;
  position: relative;
  border: none;
  cursor: pointer;
  color: var(--navbar-txt-color);
  font-weight: 700;
  font-size: 1rem;
  padding: 0.5em 1em;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  text-decoration: none;
}

.footer-links {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-inline: 3px solid var(--navbar-txt-color);
}

.footer-links a {
  color: var(--navbar-txt-color);
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.footer-contact-information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-spans {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  gap: 20px;
}

.footer-spans span,
.footer-spans span a {
  display: flex;
  gap: 10px;
  color: var(--navbar-txt-color);
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.footer-limiter p {
  font-size: clamp(1rem, 3vw, 1.2rem);
  color: var(--container-txt-color);
  line-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

@media (width < 1000px) {
  .footer-info {
    grid-template-columns: 1fr;
  }

  .footer-page-links {
    display: none;
  }

  .footer-links {
    border: 0;
    border-bottom: 3px solid var(--navbar-txt-color);
    padding-bottom: 2em;
  }

  .footer-contact-information {
    padding-block: 3em 1em;
  }
}
