/* Styling the ProjectItem component */
@property --gradient-angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

.item-container {
  position: relative;
  width: 100%;
  grid-column: span 4;
  z-index: 1;
  padding: 0.7em;
  overflow: hidden;
  clip-path: polygon(
    0 0,
    calc(100% - var(--edge-size)) 0,
    100% var(--edge-size),
    100% 100%,
    var(--edge-size) 100%,
    0 calc(100% - var(--edge-size))
  );
}

.item-container::before,
.item-container::after {
  content: "";
  position: absolute;
  inset: 0;
  background: conic-gradient(
    from var(--gradient-angle),
    var(--container-dark-color),
    var(--container-color),
    var(--container-light-color),
    var(--container-color),
    var(--container-dark-color)
  );
  animation: rotation 5s linear infinite;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.35s ease-in-out, 0.5s ease-in-out;
}

.item-container::after {
  filter: blur(3.5rem);
}

@keyframes rotation {
  0% {
    --gradient-angle: 0deg;
  }
  100% {
    --gradient-angle: 360deg;
  }
}

.item-container.double {
  grid-column: span 5;
}

.item-container.full {
  grid-column: span 9;
}

.item-background {
  background-size: cover;
  background-position: center center;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  clip-path: polygon(
    0 0,
    calc(100% - var(--edge-size)) 0,
    100% var(--edge-size),
    100% 100%,
    var(--edge-size) 100%,
    0 calc(100% - var(--edge-size))
  );
}

.item-information {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 1em;
  padding: 2em;
  opacity: 0;
  background-color: hsl(0 0% 5% / 0.7);
  transition: 0.3s ease-in-out;
  clip-path: polygon(
    0 0,
    calc(100% - var(--edge-size)) 0,
    100% var(--edge-size),
    100% 100%,
    var(--edge-size) 100%,
    0 calc(100% - var(--edge-size))
  );
}

.item-container h3,
.projects-container .item-container p {
  color: var(--container-txt-color);
}

.item-container h3 {
  margin-bottom: 1em;
}

.buttons {
  display: flex;
  gap: 1em;
  margin-top: auto;
}

.item-container:hover .item-information {
  opacity: 1;
}

.item-container:hover::before,
.item-container:hover::after {
  opacity: 1;
}

@media (width < 1000px) {
  .item-information {
    opacity: 1;
  }

  .item-container.full {
    grid-column: auto;
  }

  .buttons {
    flex-direction: column;
  }
}
