/* Styling the Skills component */
.skills-container {
  padding: 5em 1em;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.skills-limiter {
  width: 100%;
  max-width: 1500px;
}

.skills-container h2 {
  color: var(--txt-color);
  transition: 0.3s ease-in-out;
}

.skills-container p {
  font-size: clamp(1rem, 3vw, 1.2rem);
  color: var(--txt-color);
  line-height: 30px;
  margin-block: 1.5em;
  transition: 0.3s ease-in-out;
}

@media (width < 1000px) {
  .skills-container {
    padding-bottom: 0;
  }
}
