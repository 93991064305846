/* Styling the SolarSystem component */
.solar-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.planet {
  aspect-ratio: 1 / 1;
  background-size: contain;
  background-position: center center;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-color: var(--txt-color);
  transition: 0.3s ease-in-out;
}

.sun {
  width: 20%;
  margin-right: 2em;
  background-image: url("../../../images/react.png");
  position: relative;
  z-index: 1;
}

.mercury {
  width: 3%;
  background-image: url("../../../images/vue.png");
  transform: translateX(-10rem);
  transition: 0.3s ease-in-out, transform 0.5s ease-in-out 1s;
}

.venus {
  width: 4%;
  background-image: url("../../../images/c.png");
  transform: translateX(-20rem);
  transition: 0.3s ease-in-out, transform 0.5s ease-in-out 0.8s;
}

.earth {
  width: 5%;
  background-image: url("../../../images/js.png");
  transform: translateX(-30rem);
  transition: 0.3s ease-in-out, transform 0.5s ease-in-out 0.6s;
}

.mars {
  width: 4%;
  background-image: url("../../../images/python.png");
  transform: translateX(-40rem);
  transition: 0.3s ease-in-out, transform 0.5s ease-in-out 0.4s;
}

.jupiter {
  width: 15%;
  background-image: url("../../../images/html.png");
  transform: translateX(-55rem);
  transition: 0.3s ease-in-out, transform 0.5s ease-in-out 0.2s;
}

.saturn-container {
  width: 20%;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  transform: translateX(-75em);
  transition: transform 0.5s ease-in-out;
}

.saturn {
  width: 50%;
  background-image: url("../../../images/css.png");
}

.ring {
  width: 100%;
  aspect-ratio: 1/1;
  border: 50px solid var(--txt-color);
  transition: 0.3s ease-in-out;
  position: absolute;
  inset: 0;
  border-radius: 50%;
  transform: rotate3d(1, 0.1, 0.1, 75deg);
}

.up {
  width: 50%;
  clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
  background-image: url("../../../images/css.png");
  position: absolute;
}

.solar-container.visible .mercury,
.solar-container.visible .venus,
.solar-container.visible .earth,
.solar-container.visible .mars,
.solar-container.visible .jupiter,
.solar-container.visible .saturn-container {
  transform: translateX(0);
}

@media (width < 1000px) {
  .solar-container {
    flex-direction: column;
  }
  .sun,
  .saturn-container {
    width: 40%;
    margin-right: 0;
    margin-bottom: 2em;
  }
  .mercury {
    width: 6%;
    transform: translateY(-10rem);
  }
  .venus,
  .mars {
    width: 8%;
  }

  .venus {
    transform: translateY(-15rem);
  }

  .mars {
    transform: translateY(-25rem);
  }

  .earth {
    width: 10%;
    transform: translateY(-20rem);
  }

  .jupiter {
    width: 30%;
    transform: translateY(-35rem);
  }

  .saturn-container {
    transform: translateY(-50rem);
  }
}

@media (width < 500px) {
  .solar-container {
    flex-direction: column;
  }
  .sun {
    width: 60%;
    margin-right: 0;
    margin-bottom: 2em;
  }
  .mercury {
    width: 12%;
  }
  .venus,
  .mars {
    width: 16%;
  }

  .earth {
    width: 20%;
  }

  .jupiter {
    width: 50%;
  }
  .saturn-container {
    width: 80%;
  }
}
