/* Styling the App component */

/* Importing the fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600&display=swap");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 1px;
  /* outline: 1px solid lime !important; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}

h1 {
  font-size: clamp(1.8rem, 5vw, 3.5rem);
}

h2 {
  font-size: clamp(1.5rem, 5vw, 2.6rem);
}

h3 {
  font-size: clamp(1.3rem, 5vw, 2.2rem);
}

:root {
  --bg-color: hsl(0 0% 90%);
  --txt-color: hsl(0, 0%, 10%);
  --container-color: hsl(150, 10%, 36%);
  --container-btn-color: hsl(0 0% 80%);
  --container-dark-color: hsl(150, 10%, 16%);
  --container-light-color: hsl(150, 10%, 56%);
  --container-btn-txt-color: hsl(0 0% 10%);
  --container-txt-color: hsl(0 0% 90%);
  --navbar-color: hsl(240 4% 5%);
  --navbar-txt-color: hsl(0 0% 90%);
  --btn-color: hsl(0 0% 10%);
  --btn-txt-color: hsl(0 0% 80%);
  --slider-button: hsl(0 0% 90%);
  --edge-size: 1em;
}

.app-container {
  width: 100%;
  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  transition: 0.3s ease-in-out;
  background-color: var(--bg-color);
}

.dark {
  --bg-color: hsl(0 0% 15%);
  --txt-color: hsl(0, 0%, 90%);
  --container-color: hsl(150, 0%, 23%);
  --container-dark-color: hsl(150, 0%, 13%);
  --container-light-color: hsl(150, 0%, 53%);
  --btn-color: hsl(0 0% 80%);
  --btn-txt-color: hsl(0 0% 10%);
}
