/* Styling the Input component */
.input-container {
  background-color: hsl(0 0% 100% / 0.07);
  backdrop-filter: blur(3rem);
  border-bottom: 3px solid var(--container-txt-color);
  clip-path: polygon(
    var(--edge-size) 0,
    calc(100% - var(--edge-size)) 0,
    100% var(--edge-size),
    100% 100%,
    0 100%,
    0 var(--edge-size)
  );
}

.input-container input {
  background-color: transparent;
  color: hsl(0 0% 100%);
  border: none;
  padding: 1rem;
  width: 100%;
  font-weight: 900;
}

.input-container input:focus,
textarea:focus {
  outline: none;
  font-weight: 900;
}

::placeholder {
  font-weight: 900;
  font-size: 1.2em;
  color: hsl(0 0% 100%);
  transition: color 0.4s ease-in-out;
}

input:focus::placeholder,
textarea:focus::placeholder {
  color: transparent;
}
