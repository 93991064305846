/* Styling the Contact component */
.contact-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 5em 1em;
  z-index: 0;
  flex-direction: column;
}

.contact-container::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: var(--container-color);
  z-index: -2;
  transition: 0.3s ease-in-out;
  transform: skewY(3deg);
}

.contact-limiter {
  width: 100%;
  max-width: 1500px;
}

.contact-limiter h2 {
  color: var(--container-txt-color);
}

.contact-limiter p {
  font-size: clamp(1rem, 3vw, 1.2rem);
  color: var(--container-txt-color);
  line-height: 30px;
  margin-block: 1.5em;
}

form {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2em;
  padding-bottom: 2em;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.contact-limiter textarea {
  background-color: hsl(0 0% 100% / 0.07);
  backdrop-filter: blur(3rem);
  border: none;
  padding: 1rem;
  width: 100%;
  color: hsl(0 0% 100%);
  border-bottom: 3px solid var(--container-txt-color);
  clip-path: polygon(
    var(--edge-size) 0,
    calc(100% - var(--edge-size)) 0,
    100% var(--edge-size),
    100% 100%,
    0 100%,
    0 var(--edge-size)
  );
  min-height: 10em;
}

@media (width < 1000px) {
  form {
    grid-template-columns: 1fr;
  }
}
