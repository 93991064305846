/* Styling the About component */
.about-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 5em 1em;
  z-index: 0;
  flex-direction: column;
}

.about-container.cross::before,
.about-container.cross::after,
.about-container.skew-left::before,
.about-container.skew-right::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: var(--container-color);
  z-index: -2;
  transition: 0.3s ease-in-out;
}

.about-container.cross::before,
.about-container.skew-right::before {
  transform: skewY(3deg);
}

.about-container.cross::after,
.about-container.skew-left::before {
  transform: skewY(-3deg);
}

.about-limiter {
  width: 100%;
  max-width: 1500px;
  display: flex;
  gap: 1.5em;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.about-container h2 {
  color: var(--container-txt-color);
  margin-bottom: 0.3em;
}

.about-container p {
  font-size: clamp(1rem, 3vw, 1.2rem);
  color: var(--container-txt-color);
  line-height: 30px;
}
