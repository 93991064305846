/* Styling the Hero component */
.hero-container {
  width: 100%;
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1em;
  position: relative;
}

.hero-limiter {
  width: 100%;
  max-width: 1500px;
  height: 17rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.hero-text h1 {
  color: var(--txt-color);
  transition: 0.3s ease-in-out;
}

.hero-text small {
  color: var(--txt-color);
  font-size: clamp(1rem, 2vw, 1.3rem);
  transition: 0.3s ease-in-out;
}

@media (width < 650px) {
  .hero-limiter {
    height: 12rem;
  }
}
